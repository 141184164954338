import calendarConfig from "@/config/calendar";

export default {
  title: 'Default',
  isActive: true,
  date: calendarConfig.date,
  days: calendarConfig.days,
  view: calendarConfig.view,
  with_reservations: calendarConfig.with_reservations,
  with_rates: calendarConfig.with_rates,
  with_extras: false,
  with_notes: false,
  show_restrictions: false,
  show_length_of_stay: true,
  show_not_sellable: false,
  show_arrival_time: false,
  show_checkin_status: false,
  show_contacts: false,
  show_icons: false,
  show_ota_image: true,
  show_client_name: true,
  show_amounts: false,
  is_compact: false,
  is_reservation_shaped: false,
}
