export const LOGIN = "[AUTH] Login";
export const LOGIN_SUCCESS = "[AUTH] Login Success";
export const LOGIN_SUCCESS_REMEMBER = "[AUTH] Login Success Remember";
export const LOGIN_FAIL = "[AUTH] Login Fail";
export const LOGIN_REFRESH = "[AUTH] Login Refresh";
export const LOGIN_PURGE = "[AUTH] Login Purge";
export const LOGIN_RESTORE = "[AUTH] Login Restore";

export const LOGIN_MFA = "[AUTH] Login MFA";

export const LOGOUT = "[AUTH] Logout";
export const LOGOUT_SUCCESS = "[AUTH] Logout Success";
export const LOGOUT_FAIL = "[AUTH] Logout Fail";

export const REGISTER = "[AUTH] Register";
export const REGISTER_SUCCESS = "[AUTH] Register Success";
export const REGISTER_FAIL = "[AUTH] Register Fail";

export const GET_AUTH_USER = "[AUTH] User Get";
export const GET_AUTH_USER_SUCCESS = "[AUTH] User Get Success";
export const GET_AUTH_USER_FAIL = "[AUTH] User Get Fail";

export const UPDATE_AUTH_USER_LOCALE = "[AUTH] User Update Locale";
export const UPDATE_AUTH_USER_LOCALE_SUCCESS = "[AUTH] User Update Locale Success";
export const UPDATE_AUTH_USER_LOCALE_FAIL = "[AUTH] User Update Locale Fail";

export const FORGET_AUTH_USER = "[AUTH] User Forget";

export const ACCEPT_AUTH_USER_TERMS = "[AUTH] User Accept Terms";
export const ACCEPT_AUTH_USER_TERMS_SUCCESS = "[AUTH] User Accept Terms Success";
export const ACCEPT_AUTH_USER_TERMS_FAIL = "[AUTH] User Accept Terms Fail";

export const SUBSCRIBE_AUTH_USER = "[AUTH] User Subscribe";
export const UNSUBSCRIBE_AUTH_USER = "[AUTH] User Unsubscribe";

export const CHECK_AUTH_USER_SUBSCRIPTION = "[AUTH] User Check Subscription";
export const CHECK_AUTH_USER_SUBSCRIPTION_SUCCESS = "[AUTH] User Check Subscription Success";
export const CHECK_AUTH_USER_SUBSCRIPTION_FAIL = "[AUTH] User Check Subscription Fail";

export const GET_AUTH_USER_NOTIFICATIONS = "[AUTH] User Notifications Get";
export const GET_AUTH_USER_NOTIFICATIONS_SUCCESS = "[AUTH] User Notifications Get Success";
export const GET_AUTH_USER_NOTIFICATIONS_FAIL = "[AUTH] User Notifications Get Fail";

export const READ_AUTH_USER_NOTIFICATIONS = "[AUTH] User Notifications Read";
export const READ_AUTH_USER_NOTIFICATIONS_SUCCESS = "[AUTH] User Notifications Read Success";
export const READ_AUTH_USER_NOTIFICATIONS_FAIL = "[AUTH] User Notifications Read Fail";

export const UNREAD_AUTH_USER_NOTIFICATIONS = "[AUTH] User Notifications Unread";
export const UNREAD_AUTH_USER_NOTIFICATIONS_SUCCESS = "[AUTH] User Notifications Unread Success";
export const UNREAD_AUTH_USER_NOTIFICATIONS_FAIL = "[AUTH] User Notifications Unread Fail";

export const READ_ALL_AUTH_USER_NOTIFICATIONS = "[AUTH] User Notifications Read All";
export const READ_ALL_AUTH_USER_NOTIFICATIONS_SUCCESS = "[AUTH] User Notifications Read All Success";
export const READ_ALL_AUTH_USER_NOTIFICATIONS_FAIL = "[AUTH] User Notifications Read All Fail";

export const BROADCAST_AUTH_USER_NOTIFICATIONS = "[AUTH] User Notifications Broadcast";
export const BROADCAST_AUTH_USER_NOTIFICATIONS_SUCCESS = "[AUTH] User Notifications Broadcast Success";
export const BROADCAST_AUTH_USER_NOTIFICATIONS_FAIL = "[AUTH] User Notifications Broadcast Fail";

export const SEND_RESET_PASSWORD_LINK = "[AUTH] Send Reset Password Link";
export const SEND_RESET_PASSWORD_LINK_RESET = "[AUTH] Send Reset Password Link Reset";
export const SEND_RESET_PASSWORD_LINK_SUCCESS = "[AUTH] Send Reset Password Link Success";
export const SEND_RESET_PASSWORD_LINK_FAIL = "[AUTH] Send Reset Password Link Fail";

export const UPDATE_PASSWORD = "[AUTH] Update Password";
export const UPDATE_PASSWORD_RESET = "[AUTH] Update Password Reset";
export const UPDATE_PASSWORD_SUCCESS = "[AUTH] Update Password Success";
export const UPDATE_PASSWORD_FAIL = "[AUTH] Update Password Fail";